import _ from 'lodash'

export default {
  // common
  draftt_logo: 'Draftt logo',

  components: {
    common: {
      tags: {
        title: 'Tags',
      },
    },
  },

  // sidebar
  sidebar: {
    dashboard: 'Dashboard',
    policies: 'Policies',
    catalog: 'Catalog',
    draftts: 'Draftts',
    changes_roadmap: 'Changes Roadmap',
    organization: 'Organzation',
    integrations: 'Integrations',
    certificates: 'Certificates',
    settings: {
      access_keys: 'Access Keys',
      account: 'Account Settings',
      user_management: 'User Management',
    },
  },

  // dashboard
  dashboard_title: 'Overview',

  // policies
  policies_title: 'Policies',
  suggested_policies_title: 'Suggested Policies',
  custom_policies_title: 'Custom Policies',
  active: 'Active',
  policy_name: 'Policy Name',
  description: 'Description',
  affected_teams: 'Affected Teams',
  policy_summary: 'Policy Status',
  violations: 'Violations',
  compliant: 'Compliant',
  review_policy: 'Review',
  all_teams: 'All Teams',

  catalog: {
    all: {
      title: 'Catalog',
      table: {
        name: 'Name',
        type: 'Type',
        mode: 'Mode',
        versions: 'Versions',
        occurrences: 'Occurrences',
        teams: 'Teams',
        infrastructure: 'Infrastructure',
      },
    },
    specific_technology: {
      title: 'Catalog',
      table: {
        name: 'Name',
        region: 'Region',
        technology: 'Technology',
        integration_name: 'Integration name',
        updated_at: 'Last Update',
        version: 'Version',
        teams: 'Teams',
        active_integration: 'Active Integration',
      },
      ecr: {
        title: 'Amazon ECR',
        tabs: {
          repositories: {
            title: 'Repositories',
            table: {
              repo_name: 'Name',
              technologies: 'Technologies',
              latest_tag: 'Latest Tag',
              latest_tag_versions: 'Latest Tag Versions',
              last_push: 'Last Push',
              last_pull: 'Last Pull',
            },
          },
          overview: 'Overview',
        },
        repo_view: {
          table: {
            tag: 'Tag',
            arch: 'Architecture',
            os: 'Operating System',
            technologies: 'Technologies',
            versions: 'Versions',
            is_latest: {
              title: 'Latest Image',
              yes: 'Yes',
              no: 'No',
            },
            manifest_digest: 'Digest',
            pushed_at: 'Pushed At',
            last_pull: 'Last Pull',
            updated_at: 'Last Update',
          },
        },
      },
    },
  },
  // catalog

  future_timeline: {
    title: 'Future Timeline',
    component: {
      singular: 'component',
      plural: 'components',
    },
  },
  // policy
  policy_tabs: {
    suggested_draftts: 'Suggested Draftts',
    compliant: 'Compliant',
    violations: 'Violations',
    all_components: 'All',

    suggested_table: {
      technology: 'Technology',
      name: 'Component',
      urgency: 'Urgency',
      effort: 'Effort',
      org_complexity: 'Org Complexity',
      priority: 'Draftt Priority',
      desired_version: 'Required Version',
      recommended_version: 'Recommended Version',
      current_version: 'Current Version',
      due_date: 'Due Date',
      no_suggestions: 'No suggestions',
      no_due_date: 'No Due Date',
      extended_support_tooltip: 'Using this outdated version incurs an extended support fee. Expand for more details.',
      integration_name: 'Integration Name',
      collapsable_data: {
        integration_name: 'Integration',
        updated_at: 'Last Update',
        draftt_insights: 'Draftt Insights: ',
        extended_support_insights_message: 'Using this outdated version incurs an annual extended support fee of ',
      },
    },
    all_components_table: {
      technology: 'Technology',
      name: 'Component',
      is_compliant: {
        title: 'Compliant',
        no: 'No',
        yes: 'Yes',
      },
      urgency: 'Urgency',
      effort: 'Effort',
      org_complexity: 'Org Complexity',
      priority: 'Draftt Priority',
      desired_version: 'Required Version',
      recommended_version: 'Recommended Version',
      current_version: 'Current Version',
      due_date: 'Due Date',
      no_suggestions: 'No suggestions',
      no_due_date: 'No Due Date',
      extended_support_tooltip: 'Using this outdated version incurs an extended support fee. Expand for more details.',
      integration_name: 'Integration Name',
      collapsable_data: {
        integration_name: 'Integration',
        updated_at: 'Last Update',
        draftt_insights: 'Draftt Insights: ',
        extended_support_insights_message: 'Using this outdated version incurs an annual extended support fee of ',
      },
    },

    violations_table: {
      technology: 'Technology',
      name: 'Component',
      urgency: 'Urgency',
      effort: 'Effort',
      org_complexity: 'Org Complexity',
      priority: 'Draftt Priority',
      desired_version: 'Required Version',
      recommended_version: 'Recommended Version',
      current_version: 'Current Version',
      due_date: 'Due Date',
      no_violations: 'No Violations',
      no_due_date: 'No Due Date ',
      integration_name: 'Integration Name',
      collapsable_data: {
        integration_name: 'Integration',
        updated_at: 'Last Update',
      },
    },

    compliant_table: {
      technology: 'Technology',
      name: 'Component',
      urgency: 'Urgency',
      effort: 'Effort',
      org_complexity: 'Org Complexity',
      priority: 'Draftt Priority',
      desired_version: 'Required Version',
      recommended_version: 'Recommended Version',
      current_version: 'Current Version',
      due_date: 'Due Date',
      no_compliant: 'No Compliant Components',
      integration_name: 'Integration Name',
      collapsable_data: {
        integration_name: 'Integration',
        updated_at: 'Last Update',
      },
    },
  },

  settings: {
    access_keys: {
      title: 'Access Keys',
      no_tenant: 'No Associated Tenant',
    },
    user_management: {
      title: 'User Management',
      no_tenant: 'No Associated Tenant',
    },
    account: {
      title: 'Account Settings',
      name: 'Account Name',
      fetchInterval: {
        title: 'Fetch Interval',
        tooltip: 'Set how often (every x hours, a number between 1-24) you want your cloud environment to be checked for changes.',
        error: {
          between: 'Value must be between ',
          and: 'and ',
        },
      },
      update: {
        title: 'Update',
        success: 'Account updated successfully',
        failure: 'Failed to update account',
      },
    },
  },

  draftts: {
    title: 'Draftts',
    tabs: {
      active: 'Active Draftts',
      completed: 'Completed Draftts',
    },
    table: {
      name: 'Component',
      integration_name: 'Integration',
      technology: 'Technology',
      current_version: 'Current Version',
      desired_version: 'Desired Version',
      due_date: 'Due Date',
      no_due_date: 'No Due Date',
      no_draftts: 'No Draftts',
    },
  },

  draftt: {
    title: 'Draftt Overview',
    no_required_changes: 'No required changes',
    confirmation_dialog: {
      title: 'Confirm Acknowledgement',
      message: 'Once acknowledged Draftt will no longer ask for your attention for this gap and will mark it as completed. ',
      undone: 'This action cannot be undone.',
      confirm: 'Confirm',
      cancel: 'Cancel',
      success: 'Updated Draftt Successfully',
      failure: 'Failed to update Draftt',
    },
    affected_resources: {
      title: 'Affected Resources',
      name: 'Name',
      namespace: 'Namespace',
      manifest: 'Manifest',
      source: {
        title: 'Source',
        options: {
          manifest: 'Manifest',
          last_applied: 'LastApplied Configuration',
          helm_chart: 'Helm Chart',
        },
      },
      chart_name: 'Chart Name',
      chart_version: 'Chart Version',
      last_applied: 'Last Applied',
      helm_release_name: 'Helm Release Name',
      helm_release_namespace: 'Helm Release Namespace',
      helm_release_chart: 'Helm Release Chart',
      helm_release_version: 'Helm Release Version',
      source_tooltip: {
        last_applied: {
          title: 'LastApplied Configuration - ',
          description: 'indicates whether the deprecated API version was found in the last-applied-configuration annotation.',
        },
        manifest: {
          title: 'Manifest - ',
          description: 'indicates whether the deprecated API version was found in the manifest.',
        },
        helm_chart: {
          title: 'Helm Chart - ',
          description: 'indicates whether the deprecated API version was found in the Helm chart.',
        },
      },
    },
    acknowledged_by: 'Acknowledged by',
    acknowledgement_date: 'Acknowledged at',
    at: 'at',
    change_alert: {
      link_text: 'here',
    },
  },
  dashboard: {
    integration_summary: {
      title: 'Integration Summary',
      card: {
        integrations: ' Integrations',
        components: ' Components',
      },
    },
    pieCharts: {
      title: 'Policy Breakdown',
    },
    metrics_summary: {
      title: 'Summary',
      metrics: {
        total_cloud_components: 'Total Components',
        active_draftts: 'Active Draftts ',
        completed_draftts: 'Completed Draftts',
        policies: 'Policies',
        k8s_integrations: 'K8S Integrations',
        k8s_components: 'K8S Components',
      },
    },
    eolChart: {
      title: 'End of Life',
    },
    latestAndGreatestChart: {
      title: 'Latest and Greatest',
    },
    insights: {
      title: 'Draftt Insights',
      insight_description: {
        inactive_k8s_integration: 'Draftt Kubernetes Explorer Integration is inactive for',
        upgrade_notification: {
          prefix: 'It’s time to upgrade',
          due_date_passed: 'the due date was',
          upcoming_due_date: 'the due date is',
        },
      },
      card: {
        view: 'View',
      },
    },
  },
  integrations: {
    all: {
      title: 'Integrations',
      view_integration: 'View Integration',
    },
    by_type: {
      title: 'Integrations',
      create_integration: 'Create',
      table: {
        name: 'Name',
        created_at: 'Created At',
        no_active_integrations: 'No Active Integrations',
      },
    },
    instructions: {
      aws: {
        failed: 'Failed to create integration',
        title: 'Amazon Web Services Integration',
        fill_integration_name: 'Fill in the integration name:',
        integration_name: 'Integration Name',
        select_method: 'Select installation method:',
        cloud_formation_stack_set: {
          title: 'CloudFormation StackSet (Multi Account)',
          connect: `In order to connect all your AWS accounts with Draftt you will need to create an IAM Role in your organization's`,
          main_account: 'Main Account',
          step_1: {
            title: 'Step 1:',
            navigate_to: 'Navigate to',
            roles: 'Roles',
            and_click_on: 'and click on',
            custom_trust_policy: 'Custom trust policy',
            copy: 'Copy the following policy (that will allow us to assume this role)',
            click_on: 'Click on ',
            next: '"Next"',
            on_the: 'On the',
            add_permissions: '"Add permissions"',
            section: 'section, choose',
            permission_1: 'AWSOrganizationsReadOnlyAccess',
            permission_2: 'AWSCloudFormationReadOnlyAccess',
            enter: 'Enter the following name:',
            name: 'DrafttReadOnlyAccess-OrganizationLevel',
            review: 'Review the permissions and click on',
            create_role: '"Create Role"',
            enter_role_arn: 'Enter the Role ARN here:',
          },
          step_2: {
            title: 'Step 2:',
            navigate_to: 'Navigate to your',
            aws_cloud_formation: 'AWS CloudFormation Console',
            click_on: 'Click on',
            create_stackset: '"Create StackSet"',
            template_section: 'On the choose a template section - choose',
            service_managed_permissions: '"Service-managed permissions"',
            prerequisite: 'On the prerequisite section - Choose',
            template: '"Template is ready"',
            specify_template: 'On the specify template section - Choose',
            amazon_s3_url: '"Amazon S3 URL"',
            provide_link: 'and provide the following link:',
            draftt_cloud_formation_link: 'https://draftt-public.s3.amazonaws.com/draftt-onboarding-cloudformation.json',
            now_press: 'Now press',
            next: '"Next"',
          },
          step_3: {
            title: 'Step 3:',
            name_the_stack: 'Name the newly created stack, we recommend to go with something that will be easy to identify and understand as',
            draftt_stackset: 'DrafttStackSet',
            recommendation: 'We recommend to leave the default name for the',
            draftt_role_name: '“DrafttRoleName”',
            parameter: 'parameter.',
            different_name: 'If you used a different name please provide it here:',
            on_the: 'On the',
            external_id: '"ExternalId"',
            following_value: 'parameter please enter the following value: ',
            now_press: 'Now press',
            next: '"Next"',
          },
          step_4: {
            title: 'Step 4 (Optional):',
            behavior:
              'Relevant to the behavior of the cloudformation - since we’re using AWS defaults - We recommend not to make changes in this step and just press',
            next: '"Next"',
          },
          step_5: {
            title: 'Step 5:',
            on_the: 'On the',
            deployment_options: '"Set deployment options"',
            screen_choose: 'screen, choose',
            deploy_new: '"Deploy new stack"',
            deployment_target:
              'Deployment targets will set the accounts that would be joined to Draftt. You can choose the entire account or specify specific OUs (If relevant).',
            recommendation: 'We recommend not to make changes in the',
            auto_deployment: '“Auto deployment options”',
            specify_regions:
              "Specify regions Since our Cloudformation only creates a role in each account - there's no need to choose multiple regions for the stack to run.",
            only_one_region: 'Choose only one region',
            we_recommend: 'We recommend not to make changes in the deployment options section and just press',
            next: '"Next"',
          },
          step_6: {
            title: 'Step 6 (Review and Create):',
            important: 'This step is a review of all previous settings. The most important action in this step is to acknowledge and approve the',
            acknowledge: '“I acknowledge that AWS CloudFormation might create IAM resources with custom names.”',
            statement: ' statement.',
            now_press: 'Now press',
            submit: '"Submit"',
          },
          provide_stack_arn: 'Now that we created the StackSet, Please provide us with the StackSet ARN:',
          stackset_arn: {
            invalid: 'Invalid ARN',
            title: 'StackSet ARN',
          },
          stackset_role_arn: {
            title: 'Role ARN',
            invalid: 'Invalid ARN',
          },
          role_name: {
            title: 'Role Name',
            invalid: 'Invalid role name',
            default_role_name: 'DrafttAccess-Role',
          },
        },

        cloud_formation: {
          title: 'CloudFormation',
          connect: 'In order to connect your AWS account with Draftt please follow these steps:',
          step_1: {
            title: 'Step 1:',
            go_to: 'Navigate to your',
            cloud_formation: 'AWS Cloudformation console',
            click_on: 'Click on ',
            create_stack: '“Create Stack”',
            new_resources: ' - With new resources (Standard)',
            upload_template: 'On the prerequisite section - Choose ',
            choose_existing_template: '“Choose an existing template”',
            specify_template: 'On the specify template section - Choose ',
            amazon_s3_url: '“Amazon S3 URL”',
            provide_link: 'and provide the following link:',
            draftt_cloud_formation_link: 'https://draftt-public.s3.amazonaws.com/draftt-onboarding-cloudformation.json',
            now_press: 'Now press ',
            next: '“Next”',
          },
          step_2: {
            title: 'Step 2:',
            name: 'Name the newly created stack, we recommend to go with something that will be easy to identify and understand as ',
            draftt_stack: ' “DrafttStack”',
            recommendation: 'We recommend to leave the default name for the ',
            draftt_role_name: '“DrafttRoleName” ',
            parameter: 'parameter.',
            on_the: 'On the ',
            external_id: '"ExternalId"',
            following_value: 'parameter please enter the following value: ',
            now_press: 'Now press ',
            next: '“Next”',
          },
          step_3: {
            title: 'Step 3 (Optional):',
            default:
              'Relevant to the behavior of the cloudformation - since we’re using AWS defaults - We recommend not to make changes in this step and just press ',
            next: '“Next”',
          },
          step_4: {
            title: 'Step 4 (Review and Create):',
            review: 'This step is a review of all previous settings. The most important action in this step is to acknowledge and approve the ',
            acknowledge: '“I acknowledge that AWS CloudFormation might create IAM resources with custom names.”',
            statement: ' statement.',
            now_press: 'Now press ',
            submit: '“Submit”',
          },
        },
        manual: {
          title: 'Manual Installation',
          connect: 'In order to connect your AWS account with Draftt you will need to create an IAM Role of type ',
          custom_trust_policy: '“Custom trust policy” ',
          called: 'called ',
          drafft_access_role: '“DrafttAccess-Role”',
          step_1: {
            title: 'Step 1:',
            create: 'Create a new custom inline policy',
            switch_to_json: 'Switch to JSON Mode and copy the content from the following link:',
            draftt_policy_link: 'https://draftt-public.s3.amazonaws.com/DrafttReadOnlyPolicy.js',
            click_on: 'Click on ',
            actions: '"Actions"',
            then_select: 'and then select ',
            optimize_for_size: '"Optimize for size"',
            click_on_next: 'Click on ',
            next: '"Next"',
          },
          step_2: {
            title: 'Step 2:',
            name: 'Enter the following name:',
            draftt_policy_name: 'DrafttReadOnlyPolicy',
            click_on: 'Click on ',
            create_policy: '"Create Policy"',
          },
          step_3: {
            title: 'Step 3:',
            navigate_to: 'Navigate to ',
            roles: '"Roles" ',
            and_click_on: 'and click on ',
            create_role: 'Create Role',
            on_the: 'On the ',
            trusted_entity_type: '"Trusted entity type" ',
            section_choose: 'section choose ',
            custom_trust_policy: '"Custom trust policy" ',
            copy_policy: 'Copy the following policy (that will allow us to assume this role):',
            click_on: 'Click on ',
            next: '"Next"',
          },
          step_4: {
            title: 'Step 4:',
            attach: 'Attach the following policy to the role:',
            policy_1: 'SecurityAudit',
            policy_2: 'ViewOnlyAccess',
            policy_3: 'DrafttReadOnlyPolicy (the policy you created in step 2)',
            click_on: 'Click on ',
            next: '"Next"',
          },
          step_5: {
            title: 'Step 5:',
            name: 'Enter the following name:',
            role_default_name: 'DrafttAccess-Role',
            review: 'Review the permissions and click on ',
            create_role: '"Create Role"',
          },
        },
        step_role_arn: {
          title_cloud_formation: 'Step 5:',
          title_manual: 'Step 6:',
          role_arn: {
            title: 'Enter the Role ARN here:',
            input: 'Role ARN',
            validate: 'Validate',
            success: 'You have successfully created the AWS integration',
            failed: 'Failed to validate the Role ARN',
          },
        },
        create: 'Create',
        cancel: 'Cancel',
      },
      k8s_explorer: {
        title: 'Draftt Kubernetes Explorer Integration',
        in_order: 'In order to install the Kubernetes Explorer please follow these steps:',
        cancel: 'Cancel',
        create: 'Create',
        failed: 'Failed to validate integration',
        step_1: {
          title: 'Step 1:',
          select_cluster: 'Select a Cluster',
          select_cluster_error: 'Please select a cluster',
        },
        step_2: {
          title: 'Step 2:',
          helm_install:
            'We are using helm to install our charts and implement the required kubernetes resources, Please make sure you have helm installed in your machine',
          add_helm_repo: 'To add our helm chart repo run the following commands:',
          helm_install_instructions_add: 'helm repo add draftt-io https://draftt-io.github.io/helm-charts',
          helm_install_instructions_update: 'Then, update the helm repo by running:',
          helm_install_instructions_update_command: 'helm repo update',
        },
        step_3: {
          title: 'Step 3:',
          navigate_to: 'Navigate to ',
          access_keys: 'access keys',
          create: 'Create a new access key with tenant admin permissions, ',
          save: 'make sure to save the key',
        },
        step_4: {
          title: 'Step 4:',
          collector:
            'Draftt explorer is a collector tool, therefore we suggest to implement it in a “monitoring” namespace(if exists), you can implement it in any other namespace',
          create_secret:
            'Create a secret that will store Draftt’s API key securely and will be used by the chart. To allow this behavior you can use the following one liner',
          right_config: 'Make sure you are in the right k8s cluster configuration (kubectl config)',
          secret_code: 'kubectl create secret generic draftt-api-token --from-literal=drafttApiToken=<YOUR_API_KEY> -n monitoring',
        },
        step_5: {
          title: 'Step 5:',
          install_chart: 'Install the chart by running the following command:',
          helm_install_chart_prefix: 'helm install draftt-explorer draftt-io/draftt-explorer --set appConfig.clusterIdentifier="',
          helm_install_chart_cluster_name_placeholder: '<YOUR_K8S_CLUSTER_NAME>',
          helm_install_chart_suffix: '" --set appConfig.namespace="monitoring" -n monitoring ',
        },
        step_6: {
          title: 'Step 6:',
          run: 'Run the job manually for validating the integration using the following command:',
          command: 'kubectl create job --from=cronjob/draftt-explorer draftt-test-run -n monitoring',
        },
      },
      mongodb_atlas: {
        title: 'MongoDB Atlas Integration',
        integration_name: {
          fill: 'Fill in the integration name:',
          title: 'Integration Name',
          error: 'Integration name must be at least 3 characters long.',
        },
        select_level: 'Select integration level:',
        organization_level: {
          title: 'Organization',
          connect: 'In order to connect your MongoDB Atlas account with Draftt please follow these steps:',
          step_1: {
            title: 'Step 1:',
            navigate_to: 'Navigate to your',
            organizations_page: 'organizations page',
            select: 'Select the organization you want to integrate with Draftt by clicking the name of the organization',
          },
          step_2: {
            title: 'Step 2:',
            click_on: 'Click on',
            access_manager: '"Access Manager"',
            now_press: 'Now press on',
            api_keys: '"Create API Key"',
            in_the: 'In the',
            description: '"Description"',
            enter_the_following: 'please enter the following value:',
            draftt_api_key: 'Draftt Integration - Organization',
            permissions: '"Organization Permissions"',
            select_the_following: 'select the following permission:',
            read_only: '"Organization Read Only"',
            press: 'Press ',
            next: '“Next”',
            and: 'and',
          },
        },
        project_level: {
          title: 'Project',
          connect: 'In order to connect your MongoDB Atlas account with Draftt please follow these steps:',
          step_1: {
            title: 'Step 1:',
            navigate_to: 'Navigate to your',
            organizations_page: 'organizations page',
            select: 'Select the organization you want to integrate with Draftt by clicking the name of the organization',
            now_select:
              'Now select the project you want to integrate with Draftt by clicking the three dots icon in the actions column and then click on',
            project_settings: '"Visit Project Settings"',
          },
          step_2: {
            title: 'Step 2:',
            click_on: 'Click on',
            access_manager: '"Access Manager"',
            now_press: 'Now press on',
            api_keys: '"Create API Key"',
            in_the: 'In the',
            description: '"Description"',
            enter_the_following: 'please enter the following value:',
            draftt_api_key: 'Draftt Integration - Project',
            permissions: '"Project Permissions"',
            select_the_following: 'select the following permission:',
            read_only: '"Project Read Only"',
            press: 'Press ',
            next: '“Next”',
            and: 'and',
          },
        },
        step_keys: {
          enter_keys: {
            title: 'enter the generated keys:',
          },
          private_key: {
            title: 'Private Key',
            error: 'Private key should be at least 36 characters long.',
          },
          public_key: {
            title: 'Public Key',
            error: 'Public key should be at least 8 characters long.',
          },
        },
        create: 'Create',
        cancel: 'Cancel',
        failed: 'Failed to create MongoDB Atlas Integration, Please check the provided keys',
      },
    },
  },
  certificates: {
    title: 'Certificates',
    future_timeline: {
      title: 'Future Timeline',
      certificate_step: {
        singular: 'Certificate',
        plural: 'Certificates',
      },
    },
    table: {
      id: 'ID',
      type: 'Type',
      status: 'Status',
      issuer: 'Issuer',
      key_algorithm: 'Key Algorithm',
      renewal_eligibility: 'Renewal Eligibility',
      domain: 'Domain',
      integration_name: 'Integration Name',
      awsAccountId: 'AWS Account ID',
      region: 'Region',
      in_use: {
        title: 'In Use',
        yes: 'Yes',
        no: 'No',
      },
      created_at: 'Created At',
      issued_at: 'Issued At',
      not_after: 'Not After',
      not_before: 'Not Before',
      expandable: {
        created_at: 'Created At',
        key_algorithm: 'Key Algorithm',
        renewal_eligibility: 'Renewal Eligibility',
        integration_name: 'Integration',
        domains: 'Domains',
        updated_at: 'Last Update',
        in_use_by: 'In Use By',
      },
    },
  },
}
