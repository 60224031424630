import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dic from '../../../dictionary'
import { INTEGRATIONS, ROUTES } from '../../../consts'
import { getAxiosInstance } from '../../../axiosInstance'
import Loader from '../../loader'
import _ from 'lodash'
import { Grid, IconButton, Button } from '@mui/material'
import { DataGridPro, GridColDef, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import dayjs from 'dayjs'

const IntegrationsByType = () => {
  const { type } = useParams<{ type: string }>()
  const navigate = useNavigate()
  const [integrationsData, setIntegrationsData] = useState<any[]>([])
  const [integrationDisplayName, setIntegrationDisplayName] = useState('')
  const [integrationProps, setIntegrationProps] = useState<string[]>([])
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const integrationDetails = _.find(INTEGRATIONS, { type })
    if (integrationDetails) {
      setIntegrationDisplayName(integrationDetails.title)
    }

    const fetchData = async () => {
      try {
        if (type) {
          const { data } = await getAxiosInstance().get(`/integration`, { params: { type } })
          setIntegrationProps(_.uniq(_.flatMap(data, (item) => _.keys(item.details))))
          setIntegrationsData(data)
        } else {
          navigate(ROUTES.INTEGRATIONS.BY_TYPE)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [type, navigate])

  const handleBackClick = () => navigate(ROUTES.INTEGRATIONS.ALL)
  const handleCreateClick = () => navigate(_.replace(ROUTES.INTEGRATIONS.CREATE, ':type', type || ''))

  const columns: GridColDef[] = useMemo(() => {
    const dynamicColumns = _.map(integrationProps, (prop) => ({
      field: prop,
      headerName: _.startCase(prop),
      flex: 2,
    }))

    return [
      {
        field: 'name',
        headerName: dic.integrations.by_type.table.name,
        flex: 3,
      },
      ...dynamicColumns,
      {
        field: 'createdAt',
        headerName: dic.integrations.by_type.table.created_at,
        flex: 2,
        sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
      },
    ]
  }, [integrationProps])

  const rows = _.map(integrationsData, (integration) => ({
    id: _.get(integration, 'name'),
    name: _.get(integration, 'name'),
    ..._.get(integration, 'details'),
    createdAt: _.get(integration, 'createdAt') ? dayjs(_.get(integration, 'createdAt')).format('MMM D, YYYY') : undefined,
  }))

  if (loading) {
    return <Loader />
  }

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item display="flex" alignItems="center" gap="20px">
          <IconButton onClick={handleBackClick}>
            <ArrowBack />
          </IconButton>
          <img src={`https://draftt-public.s3.amazonaws.com/icons/${type}.svg`} alt={type} style={{ width: '40px', height: '40px' }} />
          <h1>
            {integrationDisplayName} {dic.integrations.by_type.title} ({integrationsData.length})
          </h1>
        </Grid>
        <Button variant="contained" endIcon={<ArrowForward />} onClick={handleCreateClick}>
          {dic.integrations.by_type.create_integration}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          disableRowSelectionOnClick
          slots={{
            toolbar: () => (
              <GridToolbarContainer sx={{ pt: '8px' }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
              </GridToolbarContainer>
            ),
          }}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default IntegrationsByType
