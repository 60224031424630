import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { DataGridPro, GridColDef, GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
import { GridColumnVisibilityModel, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import dic from '../../../../../../dictionary'
import { NOTIFICATION_TYPES, ROUTES, TAGS_DELIMITER } from '../../../../../../consts'
import { useNavigate } from 'react-router-dom'
import { AutoAwesomeOutlined, KeyboardArrowDown, KeyboardArrowUp, OpenInNew } from '@mui/icons-material'
import ComponentTags from '../../../../../commons/componentTags'
import dayjs from 'dayjs'
import { TechnologyLogo } from './repositories.style'

const RepositoriesTable = (props: any) => {
  const navigate = useNavigate()
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const allEcrComponents = props.ecrComponents

  const getLatestTagTechnologies = (latestTags: any) => {
    const allTechnologies = _.map(latestTags, (tag: any) => {
      return {
        title: `${tag.displayName} - ${tag.version}`,
        explanation: {
          type: NOTIFICATION_TYPES.WARNING,
          message: _.get(tag, 'details.config.guessedExplanation'),
        },
      }
    })

    return _.sortBy(_.uniqBy(allTechnologies, 'title'))
  }

  const getLatestTag = (repoTags: any) => {
    let result

    const latestTags = _.filter(repoTags, (tag) => _.get(tag, 'details.config.latestImage') === true)

    if (latestTags) {
      result = {
        latestTag: _.get(_.head(latestTags), 'details.config.tag'),
        latestTagVersions: getLatestTagTechnologies(latestTags),
        pushedAt: dayjs(_.get(_.head(latestTags), 'details.config.pushedAt')).format('MMM D, YYYY'),
        latestPull: dayjs(_.get(_.head(latestTags), 'details.config.latestPulledAt')).format('MMM D, YYYY'),
      }
    }

    return result
  }

  const parsedEcrComponents = _.map(allEcrComponents, (ecrComponent) => {
    return {
      ...ecrComponent,
      repoName: _.get(ecrComponent, 'details.config.repositoryDetails.name'),
    }
  })

  const ecrComponentGroupByRepo = _.groupBy(parsedEcrComponents, 'repoName')

  const repos = _.map(ecrComponentGroupByRepo, (items, repoName) => {
    const updatedAt = dayjs(_.get(_.head(items), 'updatedAt')).format('MMM D, YYYY')
    const repoDetails = _.get(_.head(items), 'details.config.repositoryDetails')
    const region = _.get(_.head(items), 'region')
    const technologies = _.chain(items).map('type').uniq().map(_.capitalize).sortBy().value()
    const latestTagConfig = getLatestTag(items)
    return { repoName, technologies, ...repoDetails, updatedAt, region, ...latestTagConfig }
  })

  const tagsFilters: GridColDef[] = useMemo(() => {
    const allTags = _.sortBy(_.uniq(_.flatMap(repos, (repo: any) => _.map(repo.tags, (tag: any) => tag.key))), [(item) => item.toLowerCase()])

    return _.map(allTags, (tag) => ({
      field: `${TAGS_DELIMITER}${tag}`,
      headerName: tag,
      flex: 1,
      hide: true,
      renderCell: (params: any) => {
        return _.get(
          _.find(params.row.tags, (t: any) => t.key === tag),
          'value',
          '',
        )
      },
    }))
  }, [repos])

  const handleRowClick = (row: any) => {
    navigate(_.replace(ROUTES.CATALOG.ECR_REPO, ':repoName', encodeURIComponent(row.id)))
  }

  const getTooltipTitle = (versions: any) => {
    return (
      <>
        {_.map(versions, (version, index) => (
          <Grid key={index} sx={{ display: 'flex', alignItems: 'start', mb: '4px' }}>
            {_.get(version, 'explanation.message') && (
              <AutoAwesomeOutlined fontSize="small" sx={{ paddingBottom: '4px', marginRight: '4px', width: '15px', color: 'white' }} />
            )}
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <span>{version.title}</span> {_.get(version, 'explanation.message') ? `: ${_.get(version, 'explanation.message')}` : ''}
            </Typography>
          </Grid>
        ))}
      </>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'repoName',
      headerName: dic.catalog.specific_technology.ecr.tabs.repositories.table.repo_name,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const awsRepoUrl = `https://console.aws.amazon.com/ecr/repositories/${params.row.type}/${params.row.registryId}/${params.row.repoName}?region=${params.row.region}`

        const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.stopPropagation()
        }

        return (
          <Grid container alignItems="center">
            {params.row.repoName}
            <Link
              href={awsRepoUrl}
              target="_blank"
              onClick={handleLinkClick}
              sx={{
                '&:hover .icon-button': {
                  opacity: 1,
                },
              }}
            >
              <IconButton
                className="icon-button"
                sx={{
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
              >
                <OpenInNew fontSize="small" />
              </IconButton>
            </Link>
          </Grid>
        )
      },
    },
    {
      field: 'technologies',
      headerName: dic.catalog.specific_technology.ecr.tabs.repositories.table.technologies,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Grid flexDirection="row" alignItems="center">
          {_.map(params.value, (tech) => (
            <Tooltip key={tech} title={tech}>
              <TechnologyLogo key={tech} src={`https://draftt-public.s3.amazonaws.com/icons/${_.toLower(tech)}.svg`} alt={tech} />
            </Tooltip>
          ))}
        </Grid>
      ),
    },
    {
      field: 'latestTag',
      headerName: dic.catalog.specific_technology.ecr.tabs.repositories.table.latest_tag,
      flex: 1,
      sortable: true,
    },
    {
      field: 'latestTagVersions',
      headerName: dic.catalog.specific_technology.ecr.tabs.repositories.table.latest_tag_versions,
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        const versions = _.get(params, 'row.latestTagVersions', [])
        const displayedVersions = _.take(versions, 2)
        const hasMore = versions.length > 2

        return (
          <Grid container flexDirection="column" justifyContent="center" alignItems="start" sx={{ height: '100%' }}>
            <Tooltip title={getTooltipTitle(versions)}>
              <Grid container flexDirection="column">
                {_.map(displayedVersions, (version: any, index: number) => (
                  <Grid item key={_.get(version, 'title', index)} sx={{ display: 'flex', alignItems: 'center' }}>
                    {_.get(version, 'explanation.message') && <AutoAwesomeOutlined fontSize="small" sx={{ marginRight: '4px', width: '15px' }} />}
                    <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                      {_.get(version, 'title')} {hasMore && index === displayedVersions.length - 1 ? '...' : ''}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Tooltip>
          </Grid>
        )
      },
      sortComparator: (v1: string, v2: string) => {
        const title1 = _.toLower(v1 || '')
        const title2 = _.toLower(v2 || '')
        return title1.localeCompare(title2)
      },
      valueGetter: (params: any) => {
        const titles = _.map(params, (version: any) => _.get(version, 'title', ''))
        return _.join(titles, ', ')
      },
    },
    {
      field: 'pushedAt',
      headerName: dic.catalog.specific_technology.ecr.tabs.repositories.table.last_push,
      flex: 1,
      sortable: true,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    {
      field: 'latestPull',
      headerName: dic.catalog.specific_technology.ecr.tabs.repositories.table.last_pull,
      flex: 1,
      sortable: true,
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(),
    },
    ...tagsFilters,
  ]

  const getDetailPanelContent = (params: any) => (
    <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
      <Grid item>
        <strong>{dic.catalog.specific_technology.table.updated_at}:</strong> {params.row.updatedAt}
      </Grid>
      {params.row.tags.length > 0 && <ComponentTags tags={params.row.tags} />}
    </Grid>
  )

  const initialVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    return _.reduce(
      tagsFilters,
      (model: GridColumnVisibilityModel, col) => {
        model[col.field] = false
        return model
      },
      {} as GridColumnVisibilityModel,
    )
  }, [tagsFilters])

  return (
    <Grid container>
      <DataGridPro
        rows={repos}
        columns={columns}
        disableRowSelectionOnClick={true}
        onRowClick={handleRowClick}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10]}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        getRowId={(row) => row.repoName}
        slots={{
          detailPanelExpandIcon: KeyboardArrowDown,
          detailPanelCollapseIcon: KeyboardArrowUp,
          toolbar: () => {
            return (
              <GridToolbarContainer sx={{ pt: '8px' }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
              </GridToolbarContainer>
            )
          },
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
            backgroundColor: '#f5f5f5',
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'repoName', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
        }}
      />
    </Grid>
  )
}

export default RepositoriesTable
